import React, { useContext, useEffect } from "react"

import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Error from "../images/404.png"

import SEO from "../components/seo"
import Layout from "../components/layout/layout"
import BreadcrumbComponent from "../components/breadcrumb/breadcrum"
import { GlobalAuthContext } from "../context/authContext"
import Spinner from "../components/spinner/spinner"

const NotFoundPage = ({ location }) => {
  const { state } = useContext(GlobalAuthContext)
  useEffect(() => {}, [state?.isAuthenticated, state?.hasProfile])
  return (
    <Layout noVerification location={location}>
      {!state?.isAuthenticated || state?.hasProfile ? (
        <>
          <SEO title="404: Not found" robot_no_index />
          <BreadcrumbComponent
            pages={[{ link: "/", name: "Home" }]}
            currentPage={{ link: "/404", name: "404" }}
          />
          <section className="not-found-page">
            <Container className="text-center">
              <Row>
                <Col lg="8" className="offset-lg-2 ">
                  <img
                    src={Error}
                    alt="404-not-found"
                    className="mb-2 image-fluid image-404"
                  />
                  <h3 className="page-not-found">Page not found!</h3>
                  <p>The page you're trying to visit doesn't exist.</p>
                </Col>
                <Col lg="4" className="offset-lg-4">
                  <Link
                    to="/"
                    className="btn primary rounded no-border w-100 pt-2 pb-2 page-not-found"
                  >
                    Go Back
                  </Link>
                </Col>
                <div className="mb-4 pb-4"></div>
              </Row>
            </Container>
          </section>
        </>
      ) : (
        <Spinner />
      )}
    </Layout>
  )
}

export default NotFoundPage
